import * as React from "react"
import { useState } from "react"
import {
  Form,
  FormGroup,
  FormControl,
  FormSelect,
  Button,
} from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/_section-contact.scss"
import { errorFindContact } from "../functions/errorFind"

const Contact = () => {
  const [errors, setErrors] = useState({})

  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    job: "",
    company: "",
    email: "",
    reason: "",
    message: "",
  })

  function submit(e) {
    e.preventDefault()
    const openErrors = errorFindContact(fields)
    if (Object.keys(openErrors).length > 0) {
      setErrors(openErrors)
    } else {
      var xhr = new XMLHttpRequest()
      var url =
        "https://api.hsforms.com/submissions/v3/integration/submit/7763866/ffcaa219-7734-44da-94d4-76f5e0e2130d"

      var data = {
        fields: [
          {
            name: "firstname",
            value: fields.firstName,
          },
          {
            name: "lastname",
            value: fields.lastName,
          },
          {
            name: "jobtitle",
            value: fields.job,
          },
          {
            name: "company",
            value: fields.company,
          },
          {
            name: "email",
            value: fields.email,
          },
          {
            name: "reason",
            value: fields.reason,
          },
          {
            name: "message",
            value: fields.message,
          },
        ],
      }

      var final_data = JSON.stringify(data)

      xhr.open("POST", url)
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          window.location.href = "/us/gated-asset-form-success"
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          console.log(xhr.responseText) // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          console.log(xhr.responseText) //Returns a 404 error if the formGuid isn't found
        }
      }

      // Sends the request

      xhr.send(final_data)
    }
  }

  return (
    <Layout>
      <Seo title="Contact our cyber security company | Vigilant" />
      <div className="container text-left my-5 grid--bg --for-it-ops-3">
        <section className="section-audit">
          <div className="container">
            <div className="row">
              <div className="section-col-1 col-sm-6">
                <h2 className="pb-5">CONTACT US</h2>
                <div className="row pb-5">
                  <h6 className="pb-0 mb-0">SALES SUPPORT</h6>
                  <p>855-238-4445 OPTION 5</p>
                </div>
                <div className="row pb-5">
                  <h6 className="pb-0 mb-0">PRODUCT SUPPORT</h6>
                  <p>855-238-4445 OPTION 2</p>
                </div>
                <div className="row pb-5">
                  <h6 className="pb-0 mb-0">UNDER INCIDENT?</h6>
                  <p>855-238-4445 OPTION 1</p>
                </div>
              </div>
              <div className="section-col-2 col-sm-6">
                <div className="container">
                  <div className="box">
                    <Form>
                      <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Control
                          value={fields.firstName}
                          className="bg-white"
                          type="fName"
                          placeholder="First Name"
                          onChange={e =>
                            setFields({ ...fields, firstName: e.target.value })
                          }
                        />

                        <span>{errors.firstName}</span>
                      </Form.Group>
                      <FormGroup className="mb-3" controlId="formLastName">
                        <FormControl
                          value={fields.lastName}
                          className="bg-white"
                          type="lName"
                          placeholder="Last Name"
                          onChange={e =>
                            setFields({ ...fields, lastName: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.lastName}</span>
                      </FormGroup>

                      <Form.Group className="mb-3" controlId="formJobTitle">
                        <Form.Control
                          value={fields.job}
                          className="bg-white"
                          type="job"
                          placeholder="Job Title"
                          onChange={e =>
                            setFields({ ...fields, job: e.target.value })
                          }
                        />

                        <span>{errors.job}</span>
                      </Form.Group>
                      <FormGroup className="mb-3" controlId="formCompany">
                        <FormControl
                          value={fields.company}
                          className="bg-white"
                          type="jobTitle"
                          placeholder="Company"
                          onChange={e =>
                            setFields({ ...fields, company: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.company}</span>
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="formBasicEmail">
                        <FormControl
                          value={fields.email}
                          className="bg-white"
                          type="email"
                          placeholder="Business Email"
                          onChange={e =>
                            setFields({ ...fields, email: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.email}</span>
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="formReason">
                        <FormSelect
                          value={fields.reason}
                          className="bg-white"
                          type="reason"
                          placeholder="Reason For Contact"
                          onChange={e =>
                            setFields({ ...fields, reason: e.target.value })
                          }
                        >
                          <option>Reason for Inquiry</option>
                          <option>Schedule a demo</option>
                          <option>Contact Sales</option>
                          <option>Product Support</option>
                          <option>Customer Support</option>
                        </FormSelect>
                        <span type="invalid">{errors.reason}</span>
                      </FormGroup>
                      <FormGroup className="mb-3" controlId="formMessage">
                        <FormControl
                          value={fields.message}
                          className="bg-white"
                          type="message"
                          as="textarea"
                          rows={5}
                          placeholder="Message"
                          onChange={e =>
                            setFields({ ...fields, message: e.target.value })
                          }
                        />
                        <span type="invalid">{errors.message}</span>
                      </FormGroup>
                      <FormGroup>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                          className="nav-section--cta-btn"
                          variant="primary"
                          type="submit"
                          onClick={submit}
                        >
                          Submit
                        </Button>
                        <p className="pt-5 white">
                          <em>
                            Vigilant is committed to ensuring the security and
                            protection of the personal information that we
                            process, and to provide a compliant and consistent
                            approach to data protection. If you have any
                            questions related to our privacy policies, please
                            contact legal@vigilantnow.com
                          </em>
                        </p>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Contact
